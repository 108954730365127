export function formatTime(time) {
  return new Date(time).toLocaleString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDate(date) {
  return new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function formatDateTimeFull(date) {
  return new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

export function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsLeft = seconds - hours * 3600 - minutes * 60;
  return `${hours}h ${minutes}m ${secondsLeft}s`;
}

export function getDayAndMonth(date) {
  return new Date(date).toLocaleString("en-GB", {
    month: "long",
    day: "numeric",
  });
}
