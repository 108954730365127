import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import RoleTableCell from "../components/misc/RoleTableCell";
import { formatTime } from "../utils/formatTime";
import EditIcon from "@mui/icons-material/Edit";
import jwt_decode from "jwt-decode";
import { getAccessToken } from "../utils/Tokens";

const User = () => {
  const { id } = useParams();

  const { isLoading, error, data } = useQuery("userData", () =>
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/users/${id}`)
      .then((res) => res.data)
  );

  const [userIpAddresses, setUserIpAddresses] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getIpAddresses = () => {
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/users/${id}/ip-addresses`)
      .then((res) => {
        setUserIpAddresses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const decodedToken = jwt_decode(getAccessToken());
  let permissions = [
    ...decodedToken.permissions.applications,
    ...decodedToken.permissions.roles,
  ];

  const enableBidding = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${id}/enablebidding`
      );
      //refresh the page
      window.location.reload();
    } catch (error) {
      console.error("An error occurred: " + error.message);
    }
  };

  const handleEnableBidding = () => {
    enableBidding();
  };

  const disableBidding = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${id}/disablebidding`
      );
      //refresh the page
      window.location.reload();
    } catch (error) {
      console.error("An error occurred: " + error.message);
    }
  };

  const handleDisableBidding = () => {
    disableBidding();
  };

  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Typography color="textPrimary">Manage User</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container item xs={12} spacing={4}>
                <Grid item>
                  <Link to={`/users/${id}/summary`}>Summary</Link>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/sms`}>SMS</Link>
                </Grid>
                <Grid item>
                  <Link
                    to={{
                      pathname: `/users/${id}/tickets`,
                      state: {
                        email: data.email,
                      },
                    }}
                  >
                    Tickets
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/transactions`}>Transactions</Link>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/lotviewings`}>Lot Viewings</Link>
                </Grid>
                {permissions.includes("qms: Administrator") && (
                  <Grid item>
                    <Link to={`/users/${id}/refunds`}>Refunds</Link>
                  </Grid>
                )}
                <Grid item>
                  <Link to={`/users/${id}/invoices`}>Invoices</Link>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/comments`}>Comments</Link>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/registered-auctions`}>Auctions</Link>
                </Grid>
                {permissions.includes("auth: view user logs") && (
                  <Grid item>
                    <Link to={`/users/${id}/logs`}>Logs</Link>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Account Details
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    to={{
                      pathname: `/users/${id}/edit`,
                      state: {
                        userId: id,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        accountStatus: data.locked.data[0],
                        emailStatus: data.emailVerified.data[0],
                      },
                    }}
                  >
                    <Button startIcon={<EditIcon />}>edit</Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    value={`${data.firstName} ${data.lastName}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    value={data.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="User Id"
                    value={data.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Account Status"
                      value={data.locked.data[0] ? "Locked" : "Active"}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email Status"
                      value={
                        data.emailVerified.data[0] ? "Approved" : "Unapproved"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Creation User ID"
                    value={data.creationUserId || "-"}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      xs={6}
                      label="Creation Date"
                      value={formatTime(data.creationDate)}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      xs={6}
                      label="Bidding Status"
                      value={
                        data?.permissions?.roles?.includes(
                          "Bidding: Bidding Disabled"
                        )
                          ? "Disabled"
                          : "Enabled"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                    {data?.permissions?.roles?.includes(
                      "Bidding: Bidding Disabled"
                    ) ? (
                      <Grid item>
                        <Button onClick={handleEnableBidding}>
                          Enable Bidding
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button onClick={handleDisableBidding}>
                          Disable Bidding
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Modification User ID"
                    value={data.modificationUserId || "-"}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Modification Date"
                    value={formatTime(data.modificationDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Registration IP Address"
                    value={data.registerIpAddress || "-"}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Logged In Date"
                    value={
                      data.lastLoggedInDate
                        ? formatTime(data.lastLoggedInDate)
                        : "-"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">Account Permissions</Typography>
                </Grid>
                <Grid item>
                  {permissions.includes("auth: Manage Permissions") && (
                    <Link
                      to={{
                        pathname: `/users/${id}/permissions`,
                        state: {
                          userId: id,
                          permissions: data.permissions,
                        },
                      }}
                    >
                      <Button startIcon={<EditIcon />}>edit</Button>
                    </Link>
                  )}
                </Grid>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Applications</TableCell>
                      <TableCell>Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.permissions.applications.map((application) => {
                      return (
                        <TableRow key={application} hover>
                          <TableCell>{application}</TableCell>
                          <TableCell>
                            <Table size="small">
                              <TableBody>
                                {data.permissions.roles
                                  .filter((role) => role.includes(application))
                                  .map((role) => {
                                    return (
                                      <TableRow key={role} hover>
                                        <RoleTableCell>
                                          {role.slice(
                                            role.lastIndexOf(":") + 2
                                          )}
                                        </RoleTableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container>
                <Typography variant="h6">IP Addresses</Typography>

                {userIpAddresses.length === 0 && (
                  <Box ml={2}>
                    <Button variant="outlined" onClick={getIpAddresses}>
                      View All
                    </Button>
                  </Box>
                )}
              </Grid>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>IP Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(userIpAddresses.length > 0
                      ? userIpAddresses
                      : data.recentIpAddresses
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((ipAddress) => {
                        return (
                          <TableRow key={ipAddress.id} hover>
                            <TableCell>
                              {formatTime(ipAddress.creationDate)}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={{
                                  pathname: `/users/ip-address/${ipAddress.ipAddress}`,
                                  state: { userId: id },
                                }}
                              >
                                {ipAddress.ipAddress}
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {userIpAddresses.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={userIpAddresses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                )}
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default User;
