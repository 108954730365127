const UserLogTypes = Object.freeze({
  ENABLE_BIDDING: 1,
  DISABLE_BIDDING: 2,
  LOCK_ACCOUNT: 3,
  UNLOCK_ACCOUNT: 4,
  GET_IP_ADDRESSES: 5,
  REMOVED_USER_ROLE: 6,
  ADDED_USER_ROLE: 7,
  VIEWED_USER_LOGS: 8,
  VIEWED_USER: 9,
  toString: (type) => {
    switch (type) {
      case UserLogTypes.ENABLE_BIDDING:
        return "Enable Bidding";
      case UserLogTypes.DISABLE_BIDDING:
        return "Disable Bidding";
      case UserLogTypes.LOCK_ACCOUNT:
        return "Lock Account";
      case UserLogTypes.UNLOCK_ACCOUNT:
        return "Unlock Account";
      case UserLogTypes.GET_IP_ADDRESSES:
        return "Get IP Addresses";
      case UserLogTypes.REMOVED_USER_ROLE:
        return "Removed User Role";
      case UserLogTypes.ADDED_USER_ROLE:
        return "Added User Role";
      case UserLogTypes.VIEWED_USER_LOGS:
        return "Viewed User Logs";
      case UserLogTypes.VIEWED_USER:
        return "Viewed User";
      default:
        return "Unknown";
    }
  },
});

export default UserLogTypes;
