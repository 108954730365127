import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "../Routes/PublicRoute";
import PrivateRoute from "../Routes/PrivateRoute";
import Login from "../../pages/Login";
import Users from "../../pages/Users";
import User from "../../pages/User";
import Qms from "../../pages/Qms";
import QmsCreate from "../../pages/QmsCreate";
import EditUser from "../../pages/EditUser";
import ChangePassword from "../../pages/ChangePassword";
import theme from "../../theme/theme";
import {
  CircularProgress,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuctionHouseProvider } from "../../providers/AuctionHouseProvider";
import Permissions from "../../pages/Permissions";
import Dashboard from "../Dashboard/Dashboard";
import QmsView from "../../pages/QmsView";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAccessToken, getRefreshToken } from "../../utils/Tokens";
import Articles from "../../pages/Articles";
import CreateArticle from "../../pages/CreateArticle";
import EditArticle from "../../pages/EditArticle";
import UserTickets from "../../pages/UserTickets";
import QmsTopics from "../../pages/QmsTopics";
import QmsResponses from "../../pages/QmsResponses";
import Pages from "../../pages/Pages";
import CreatePage from "../../pages/CreatePage";
import EditPage from "../../pages/EditPage";
import Banners from "../../pages/Banners";
import CreateBanner from "../../pages/CreateBanner";
import EditBanner from "../../pages/EditBanner";
import IpAddresses from "../../pages/IpAddresses";
import Layout from "../Layout";
import Auctions from "../../pages/Auctions";
import CreateAuction from "../../pages/CreateAuction";
import AuctionHouses from "../../pages/AuctionHouses";
import CreateAuctionHouse from "../../pages/CreateAuctionHouse";
import CreateAuctionTemplate from "../../pages/CreateAuctionTemplate";
import Categories from "../../pages/Categories";
import CategoryImages from "../../pages/CategoryImages";
import CategoriesSEO from "../../pages/CategoriesSEO";
import EditHelpArticle from "../../pages/EditHelpArticle";
import HelpArticles from "../../pages/HelpArticles";
import CreateHelpArticles from "../../pages/CreateHelpArticles";
import HelpArticleTopics from "../../pages/HelpArticleTopics";
import Cookies from "universal-cookie/es6";
import ManageLotEnquiries from "../../pages/ManageLotEnquiries";
import ManageLotEnquiry from "../../pages/ManageLotEnquiry";
import AuctionStats from "../../pages/AuctionStats";
import Transactions from "../../pages/Transactions";
import UserTransactions from "../../pages/UserTransactions";
import Transaction from "../../pages/Transaction";
import Memberships from "../../pages/Memberships";
import Membership from "../../pages/Membership";
import ViewAuction from "../../pages/Auction";
import LotBidHistory from "../../pages/LotBidHistory";
import EditAuction from "../../pages/EditAuction";
import LotEnquiryResponses from "../../pages/LotEnquiryResponses";
import ManageViewings from "../../pages/ManageViewings";
import ManageRecurringViewings from "../../pages/ManageRecurringViewings";
import ManageBookings from "../../pages/ManageViewBookings";
import ManageBooking from "../../pages/ManageViewBooking";
import ManageViewing from "../../pages/ManageViewing";
import ManageRecurringViewing from "../../pages/ManageRecurringViewing";
import UserComments from "../../pages/UserComments";
import UserRegisteredAuctions from "../../pages/UserRegisteredAuctions";
import UserInvoices from "../../pages/UserInvoices";
import UserSummary from "../../pages/UserSummary";
import UserLotViewings from "../../pages/UserLotViewings";
import Returns from "../../pages/Returns";
import ReturnsCreate from "../../pages/ReturnsCreate";
import ReturnsView from "../../pages/ReturnsView";
import Printers from "../../pages/Printers";
import PrintersCreate from "../../pages/PrintersCreate";
import VerificationIds from "../../pages/VerificationIds";
import VerificationId from "../../pages/VerificationId";
import UserSms from "../../pages/UserSms";
import AuctionBuyerStats from "../../pages/AuctionBuyerStats";
import StockCheck from "../../pages/StockCheck";
import StockCheckPickOrder from "../../pages/StockCheckPickOrder";
import ManageUserFeedback from "../../pages/ManageUserFeedback";
import UserFeedback from "../../pages/UserFeedback";
import RefundTypes from "../../pages/RefundTypes";
import Refunds from "../../pages/Refunds";
import AssignUsers from "../../pages/AssignUsers";
import CreateRefund from "../../pages/CreateRefund";
import EditRefund from "../../pages/EditRefund";
import UserRefunds from "../../pages/UserRefunds";
import CustomersQueue from "../../pages/CustomersQueue";
import CustomersAddToQueue from "../../pages/CustomersAddToQueue";
import CustomersBookings from "../../pages/CustomersBookings";
import CustomerInvoices from "../../pages/CustomerInvoices";
import CustomerReprimand from "../../pages/CustomerReprimand";
import CustomerQueuePickingSlots from "../../pages/CustomerQueuePickingSlots";
import CustomerQueueVariables from "../../pages/CustomerQueueVariables";
import NotificationsCreate from "../../pages/NotificationsCreate";
import Notifications from "../../pages/Notifications";
import NotificationUpdate from "../../pages/NotificationUpdate";
import RandomSearch from "../../pages/RandomSearch";
import ReportEstatesCost from "../../pages/ReportEstatesCost";
import ReportEstateCost from "../../pages/ReportEstateCost";
import ReportLotsRange from "../../pages/ReportLotsRange";
import ReportStocks from "../../pages/ReportStocks";
import ReportStockIntegrity from "../../pages/ReportStockIntegrity";
import ReportStockIntegrityView from "../ReportStockIntegrityView";
import ReportLotsCondition from "../../pages/ReportLotsCondition";
import ReportCollections from "../../pages/ReportCollections";
import ContentSeoBanners from "../../pages/ContentSeoBanners";
import ContentSeoBannersCreate from "../../pages/ContentSeoBannersCreate";
import ContentSeoBannersEdit from "../../pages/ContentSeoBannersEdit";
import ContentLandingPages from "../../pages/ContentLandingPages";
import ContentLandingPage from "../../pages/ContentLandingPage";
import ContentLandingPageCreate from "../../pages/ContentLadingPageCreate";
import CreateUsers from "../../pages/CreateUsers";
import ResetLot from "../../pages/ResetLot";
import ReinstateInvoice from "../../pages/ReinstateInvoice";
import AomsCloseAuctions from "../../pages/AomsCloseAuctions";
import AomsCloseAuctionsView from "../../pages/AomsCloseAuctionsView";
import AomsSBIPrintBatcher from "../../pages/AomsSBIPrintBatcher";
import EditAuctionTemplate from "../../pages/EditAuctionTemplate";
import AuctionTemplates from "../../pages/AuctionTemplates";
import ReportsWeeklyTotals from "../../pages/ReportsWeeklyTotals";
import ReportPickIssues from "../../pages/ReportPickIssues";
import ReportInbound from "../../pages/ReportInbound";
import ReportDispatch from "../../pages/ReportDispatch";
import ReportStats from "../../pages/ReportStats";
import ReportStatsStockContainers from "../../pages/ReportStatsStockContainers";
import ReportStatsUsers from "../../pages/ReportUsersStats";
import ReportLotterPerformance from "../../pages/ReportLotterPerformance";
import ReportDispatcherPerformance from "../../pages/ReportDispatcherPerformance";
import ReportPickerPerformance from "../../pages/ReportPickerPerformance";
import ReportStatsDispatch from "../../pages/ReportStatsDispatch";
import B2BBlogArticles from "../../pages/B2BBlogArticles";
import B2BAddBlogArticle from "../../pages/B2BAddBlogArticle";
import B2BEditBlogArticle from "../../pages/B2BEditBlogArticle";
import B2BCategoryArticles from "../../pages/B2BCategoryArticles";
import B2BAddCategoryArticle from "../../pages/B2BAddCategoryArticle";
import B2BEditCategoryArticle from "../../pages/B2BEditCategoryArticle";
import ReportsMonthlyTotals from "../../pages/ReportsMonthlyTotals";
import AomsInvoiceDispatcher from "../../pages/AomsInvoiceDispatcher/AomsInvoiceDispatcher";
import AomsInvoiceLotters from "../../pages/AomsInvoiceLotters/AomsInvoiceLotters";
import AomsLotStickerPrint from "../../pages/AomsLotStickerPrint/AomsLotStickerPrint";
import TimesheetAttendance from "../../pages/TimesheetsAttendance";
import TimesheetEmployee from "../../pages/TimesheetEmployee";
import TimesheetAdd from "../../pages/TimesheetAdd";
import TimesheetAddEmployee from "../../pages/TimesheetAddEmployee";
import Timesheets from "../../pages/Timesheets";
import TimesheetManageEmployees from "../../pages/TimesheetManageEmployees";
import TimesheetMy from "../../pages/TimesheetMy";
import TimesheetHolidays from "../../pages/TimesheetHolidays";
import TimesheetAddHoliday from "../../pages/TimesheetAddHoliday";
import TimesheetShiftPatterns from "../../pages/TimesheetShiftPatterns";
import TotalInbounds from "../../pages/salesstats/TotalInbounds";
import AuctionHouseTotalInbounds from "../../pages/salesstats/AuctionHouseTotalInbounds";
import VendorTotalInbounds from "../../pages/salesstats/VendorTotalInbounds";
import VendorHammerStats from "../../pages/salesstats/VendorHamerStats";
import VendorTopTen from "../../pages/salesstats/VendorTopTen";
import VendorInboundByDateRange from "../../pages/salesstats/VendorInboundByDateRange";
import ReportUncollectedInvoices from "../../pages/ReportUncollectedInvoices";
import UserLogs from "../../pages/UserLogs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const accessToken = getAccessToken();
    const cookies = new Cookies();
    const refreshToken = getRefreshToken();
    if (refreshToken && !accessToken) {
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/token/${refreshToken}`)
        .then((res) => {
          const accessToken = res.data.accessToken;
          sessionStorage.setItem("ACCESS_TOKEN", accessToken);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("TOKEN");
          cookies.remove("refreshToken");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    // Set axios interceptors
    const refreshAuthLogic = (failedRequest) => {
      return axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/token/${getRefreshToken()}`)
        .then((res) => {
          const accessToken = res.data.accessToken;
          sessionStorage.setItem("ACCESS_TOKEN", accessToken);
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + accessToken;
          return Promise.resolve();
        })
        .catch((err) => {
          console.error(err);
          console.log("dfkjbndjkfkdg");

          return Promise.reject(err);
        });
    };

    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    axios.interceptors.request.use((request) => {
      request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
      return request;
    });
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {!isLoading ? (
            <QueryClientProvider client={queryClient}>
              <AuctionHouseProvider>
                <BrowserRouter>
                  <Switch>
                    <PublicRoute exact path="/" component={Login} />
                    <Layout>
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/users"
                        component={Users}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/users/:id"
                        component={User}
                      />
                      <PrivateRoute
                        requiredPermission="auth: view user logs"
                        exact
                        path="/users/:id/logs"
                        component={UserLogs}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/edit"
                        component={EditUser}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/tickets"
                        component={UserTickets}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/sms"
                        component={UserSms}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/change-password"
                        component={ChangePassword}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/permissions"
                        component={Permissions}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/ip-address/:ipAddress"
                        component={IpAddresses}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/transactions"
                        component={Transactions}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:userId/transactions/:transactionId"
                        component={Transaction}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:userId/transactions"
                        component={UserTransactions}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/memberships"
                        component={Memberships}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/memberships/:id"
                        component={Membership}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/summary"
                        component={UserSummary}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/comments"
                        component={UserComments}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/registered-auctions"
                        component={UserRegisteredAuctions}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/invoices"
                        component={UserInvoices}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/users/:id/lotviewings"
                        component={UserLotViewings}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/qms/tickets"
                        component={Qms}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/qms/create"
                        component={QmsCreate}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/qms/topics"
                        component={QmsTopics}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/qms/responses"
                        component={QmsResponses}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/qms/tickets/:id"
                        component={QmsView}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/returns"
                        component={Returns}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/returns/create"
                        component={ReturnsCreate}
                      />
                      <PrivateRoute
                        requiredPermission="qms"
                        exact
                        path="/returns/view/:id"
                        component={ReturnsView}
                      />
                      <PrivateRoute
                        requiredPermission="printers"
                        exact
                        path="/printers"
                        component={Printers}
                      />
                      <PrivateRoute
                        requiredPermission="printers"
                        exact
                        path="/printers/create"
                        component={PrintersCreate}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/articles"
                        component={Articles}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/articles/new"
                        component={CreateArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/articles/:id/edit"
                        component={EditArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/pages"
                        component={Pages}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/pages/new"
                        component={CreatePage}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/pages/:id/edit"
                        component={EditPage}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/help-articles"
                        component={HelpArticles}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/help-articles/new"
                        component={CreateHelpArticles}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/help-articles/:id/edit"
                        component={EditHelpArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/help-article-topics"
                        component={HelpArticleTopics}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/banners"
                        component={Banners}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/banners/new"
                        component={CreateBanner}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/banners/:id/edit"
                        component={EditBanner}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/seo-banners"
                        component={ContentSeoBanners}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/seo-banners/create"
                        component={ContentSeoBannersCreate}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/seo-banners/:id/edit"
                        component={ContentSeoBannersEdit}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/landing-pages"
                        component={ContentLandingPages}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/landing-pages/update/:id"
                        component={ContentLandingPage}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/content/landing-pages/add"
                        component={ContentLandingPageCreate}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Categories"
                        exact
                        path="/categories"
                        component={Categories}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Categories"
                        exact
                        path="/categories/:id/images"
                        component={CategoryImages}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Categories"
                        exact
                        path="/categories/:id/manage-seo"
                        component={CategoriesSEO}
                      />

                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctions"
                        component={Auctions}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctions-new"
                        component={CreateAuction}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctions/:id"
                        component={ViewAuction}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctions/:id/edit"
                        component={EditAuction}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctiontemplates/create"
                        component={CreateAuctionTemplate}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctiontemplates/:id"
                        component={EditAuctionTemplate}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctiontemplates"
                        component={AuctionTemplates}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auctions"
                        exact
                        path="/auctions/:auctionId/lots/:lotId/bidhistory"
                        component={LotBidHistory}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auction Houses"
                        exact
                        path="/auction-houses"
                        component={AuctionHouses}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Auction Houses"
                        exact
                        path="/auction-houses/new"
                        component={CreateAuctionHouse}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Lot Enquiries"
                        exact
                        path="/lot-enquiries"
                        component={ManageLotEnquiries}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Lot Enquiries"
                        exact
                        path="/lot-enquiries-responses"
                        component={LotEnquiryResponses}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Lot Enquiries"
                        exact
                        path="/lot-enquiries/:id"
                        component={ManageLotEnquiry}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: View Auction Stats"
                        exact
                        path="/auction-stats"
                        component={AuctionStats}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/lot-viewings"
                        component={ManageViewings}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/lot-viewings/:id"
                        component={ManageViewing}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/recurring-lot-viewings"
                        component={ManageRecurringViewings}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/recurring-lot-viewings/:id"
                        component={ManageRecurringViewing}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/lot-view-booking"
                        component={ManageBookings}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Viewings"
                        exact
                        path="/lot-view-booking/:id"
                        component={ManageBooking}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/verification-ids"
                        component={VerificationIds}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/verification-ids/:id/:userId"
                        component={VerificationId}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: View Auction Stats"
                        exact
                        path="/auction-stats/:id/:auctionTitle/buyers"
                        component={AuctionBuyerStats}
                      />
                      <PrivateRoute
                        requiredPermission="imageserver"
                        exact
                        path="/stock-check"
                        component={StockCheck}
                      />
                      <PrivateRoute
                        requiredPermission="imageserver"
                        exact
                        path="/stock-check/:pickOrderId/pick-order"
                        component={StockCheckPickOrder}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/feedback"
                        component={ManageUserFeedback}
                      />
                      <PrivateRoute
                        requiredPermission="auth: manageUsers"
                        exact
                        path="/feedback/:id"
                        component={UserFeedback}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/assign-users"
                        component={AssignUsers}
                      />
                      <PrivateRoute
                        requiredPermission="qms: Administrator"
                        exact
                        path="/qms/refunds"
                        component={Refunds}
                      />
                      <PrivateRoute
                        requiredPermission="qms: Administrator"
                        exact
                        path="/qms/refunds/new"
                        component={CreateRefund}
                      />
                      <PrivateRoute
                        requiredPermission="qms: Administrator"
                        exact
                        path="/qms/refunds/:id/edit"
                        component={EditRefund}
                      />
                      <PrivateRoute
                        requiredPermission="qms: Administrator"
                        exact
                        path="/qms/refunds/types"
                        component={RefundTypes}
                      />
                      <PrivateRoute
                        requiredPermission="qms: Administrator"
                        exact
                        path="/users/:id/refunds"
                        component={UserRefunds}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/notifications/create"
                        component={NotificationsCreate}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/notifications"
                        component={Notifications}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/notifications/update/:id"
                        component={NotificationUpdate}
                      />
                      <PrivateRoute
                        requiredPermission="collections"
                        exact
                        path="/queue"
                        component={CustomersQueue}
                      />
                      <PrivateRoute
                        requiredPermission="collections: collections-admin"
                        exact
                        path="/queue/bookings"
                        component={CustomersBookings}
                      />
                      <PrivateRoute
                        requiredPermission="collections: collections-admin"
                        exact
                        path="/queue/picking-slots"
                        component={CustomerQueuePickingSlots}
                      />
                      <PrivateRoute
                        requiredPermission="collections: collections-admin"
                        exact
                        path="/queue/invoices"
                        component={CustomerInvoices}
                      />
                      <PrivateRoute
                        requiredPermission="collections: collections-admin"
                        exact
                        path="/queue/reprimand"
                        component={CustomerReprimand}
                      />
                      <PrivateRoute
                        requiredPermission="collections: collections-admin"
                        exact
                        path="/queue/variables"
                        component={CustomerQueueVariables}
                      />
                      <PrivateRoute
                        requiredPermission="collections"
                        exact
                        path="/queue/add"
                        component={CustomersAddToQueue}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/random-search"
                        component={RandomSearch}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/estates-cost"
                        component={ReportEstatesCost}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/estates-cost/:estateNo"
                        component={ReportEstateCost}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/lots-range"
                        component={ReportLotsRange}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/stocks"
                        component={ReportStocks}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/stockintegrity"
                        component={ReportStockIntegrity}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/stockintegrity/:id"
                        component={ReportStockIntegrityView}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/lots-condition"
                        component={ReportLotsCondition}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/collections"
                        component={ReportCollections}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/pick-issues"
                        component={ReportPickIssues}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/inbound"
                        component={ReportInbound}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/reports/dispatch"
                        component={ReportDispatch}
                      />
                      <PrivateRoute
                        requiredPermission="reports"
                        exact
                        path="/stats/stats"
                        component={ReportStats}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/inbound"
                        component={TotalInbounds}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/auctionhouses/estates"
                        component={AuctionHouseTotalInbounds}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/vendors/estates"
                        component={VendorTotalInbounds}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/vendors/hammer"
                        component={VendorHammerStats}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/vendors/topten"
                        component={VendorTopTen}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Sales Reports"
                        exact
                        path="/stats/inbound/vendors"
                        component={VendorInboundByDateRange}
                      />

                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/stock-container"
                        component={ReportStatsStockContainers}
                      />
                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/users-stats"
                        component={ReportStatsUsers}
                      />
                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/lotter-performance"
                        component={ReportLotterPerformance}
                      />
                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/dispatcher-performance"
                        component={ReportDispatcherPerformance}
                      />
                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/picker-performance"
                        component={ReportPickerPerformance}
                      />
                      <PrivateRoute
                        requiredPermission={"reports"}
                        exact
                        path="/stats/dispatch"
                        component={ReportStatsDispatch}
                      />
                      <PrivateRoute
                        requiredPermission="auth: createUsers"
                        exact
                        path="/create-users"
                        component={CreateUsers}
                      />
                      <PrivateRoute
                        requiredPermission="auth: resetLot"
                        exact
                        path="/reset-lot"
                        component={ResetLot}
                      />
                      <PrivateRoute
                        requiredPermission="auth: resetLot"
                        exact
                        path="/reinstate-invoice"
                        component={ReinstateInvoice}
                      />
                      <PrivateRoute
                        requiredPermission="aoms"
                        exact
                        path="/aoms/lot-stickers"
                        component={AomsLotStickerPrint}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Close Auctions"
                        exact
                        path="/aoms/close-auctions"
                        component={AomsCloseAuctions}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Final Reports"
                        exact
                        path="/reports/weekly-totals"
                        component={ReportsWeeklyTotals}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Final Reports"
                        exact
                        path="/reports/monthly-totals"
                        component={ReportsMonthlyTotals}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Close Auctions"
                        exact
                        path="/aoms/close-auctions/:id"
                        component={AomsCloseAuctionsView}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Accounts"
                        exact
                        path="/aoms/invoice-dispatcher"
                        component={AomsInvoiceDispatcher}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Accounts"
                        exact
                        path="/aoms/invoice-lotters"
                        component={AomsInvoiceLotters}
                      />
                      <PrivateRoute
                        requiredPermission="aoms: Accounts"
                        exact
                        path="/aoms/sbi-print-batcher"
                        component={AomsSBIPrintBatcher}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/blog-articles"
                        component={B2BBlogArticles}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/blog-articles/add"
                        component={B2BAddBlogArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/blog-articles/update/:id"
                        component={B2BEditBlogArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/category-articles"
                        component={B2BCategoryArticles}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/category-articles/add"
                        component={B2BAddCategoryArticle}
                      />
                      <PrivateRoute
                        requiredPermission="scweb: Manage Content"
                        exact
                        path="/b2b/category-articles/update/:id"
                        component={B2BEditCategoryArticle}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets"
                        component={Timesheets}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/add"
                        component={TimesheetAdd}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/add-employee"
                        component={TimesheetAddEmployee}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/attendance"
                        component={TimesheetAttendance}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/employee/:id"
                        component={TimesheetEmployee}
                      />
                      <PrivateRoute
                        requiredPermission={"timesheet"}
                        exact
                        path="/timesheets/my-timesheet"
                        component={TimesheetMy}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/manage-employees"
                        component={TimesheetManageEmployees}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Holidays"
                        exact
                        path="/timesheets/holidays"
                        component={TimesheetHolidays}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/add-holiday"
                        component={TimesheetAddHoliday}
                      />
                      <PrivateRoute
                        requiredPermission="timesheet: Timesheet Admin"
                        exact
                        path="/timesheets/shift-patterns"
                        component={TimesheetShiftPatterns}
                      />
                      <PrivateRoute
                        requiredPermission="auth"
                        exact
                        path="/reports/uncollected-invoices"
                        component={ReportUncollectedInvoices}
                      />
                    </Layout>
                  </Switch>
                </BrowserRouter>
              </AuctionHouseProvider>
            </QueryClientProvider>
          ) : null}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
