import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserLogTypes from "../constants/UserLogTypes";
import { formatDateTimeFull } from "../utils/formatTime";

const UserLogs = () => {
  const [logs, setLogs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { id } = useParams();

  const getLogs = () => {
    setIsLoading(true);
    const startRowIndex = page * rowsPerPage;
    const maximumRows = rowsPerPage;
    axios
      .get(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${id}/logs?startRowIndex=${startRowIndex}&maximumRows=${maximumRows}`
      )
      .then((res) => {
        setLogs(res.data.results);
        setTotalRows(res.data.totalRows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  return (
    <Fragment>
      {isLoading && <CircularProgress />}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/users">Users</Link>
            <Link to={`/users/${id}`}>Manage User</Link>
            <Typography color="textPrimary">User Logs</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Log ID</TableCell>
                  <TableCell>Log Date</TableCell>
                  <TableCell>Creation User ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>
                      {formatDateTimeFull(log.creationDate)}
                    </TableCell>
                    <TableCell>
                      <Link to={`/users/${log.creationUserId}`}>
                        {log.creationUserId}
                      </Link>
                    </TableCell>
                    <TableCell>{UserLogTypes.toString(log.type)}</TableCell>
                    <TableCell>{log.notes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 200]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) =>
                setRowsPerPage(event.target.value)
              }
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserLogs;
